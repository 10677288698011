import React, { FC } from 'react';
import styles from './date-field.module.scss';
import {
  globalSearchEndDateState,
  globalSearchStartDateState,
  SuggestionTypes,
  useGlobalSearchContext,
} from '../../../../store/global-search.context';
import { GlobalSearchInput, Input } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Icon } from '@digando/react-component-library';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  isGlobalSearchHeaderActiveState,
} from './../global-search.state';
import { useDateInfo } from '../../../../hooks/use-date-info';
import ReactDatePicker, { ReactDatePickerStyle } from './../../../date-picker/date-picker';
import { addBusinessDays, getWorkingDays } from '../../../../hooks/use-product-search-helper';

type FromDateFieldProps = {
  identifier: string;
  compact?: boolean;
}

export const FromDateField: FC<FromDateFieldProps> = ({ identifier, compact = false }) => {
  const {
    openSuggestion,
  } = useGlobalSearchContext();

  const [startDate, setStartDate] = useRecoilState(globalSearchStartDateState);
  const [endDate, setEndDate] = useRecoilState(globalSearchEndDateState);

  const [t] = useTranslation(['search-component', 'common']);
  const setIsGlobalSearchHeaderActive = useSetRecoilState(isGlobalSearchHeaderActiveState);
  const dateInfo = useDateInfo();

  return (
    <>
      <div className={styles.desktopDateFieldWrapper}>
        <GlobalSearchInput
          id={`global-search-from-date-${identifier}-desktop`}
          name={`global-search-from-date-${identifier}-desktop`}
          value={startDate.format('DD.MM.YYYY')}
          defaultValue={startDate.format('DD.MM.YYYY')}
          label={t('search-component:label-start-date')}
          onChange={() => {
            return;
          }}
          onFocus={(event): void => {
            setIsGlobalSearchHeaderActive(true);
            openSuggestion(SuggestionTypes.DATE_START);
          }}
          compact={compact}
        />
      </div>

      <div className={classNames({
        [styles.mobileDateFieldWrapper]: true,
        [styles.fromDateField]: true,
      })}>
        <ReactDatePickerStyle>
          <ReactDatePicker
            selected={startDate.toDate()}
            excludeDates={dateInfo.nonWorkingDays.dates}
            focusSelectedMonth={true}
            allowSameDay={true}
            minDate={new Date()}
            dateFormat={'dd.MM.yyyy'}
            customInput={
              <Input
                id={`global-search-from-date-${identifier}-mobile`}
                name={`global-search-from-date-${identifier}-mobile`}
                type='readonly'
                label={t('search-component:label-start-date')}
                suffix={
                  <Icon size={'25px'} icon={'calendar'} color={'var(--primary-color)'} />
                }
                suffixAction={'focus'}
              />
            }
            previousMonthButtonLabel={<Icon icon={'right-slide'} size={'25px'} />}
            nextMonthButtonLabel={<Icon icon={'right-slide'} size={'25px'} />}
            onChange={(dateString): void => {
              if ('object' !== typeof dateString) return;

              const date = dayjs(dateString);
              const dateDiff = getWorkingDays(startDate, endDate, dateInfo.nonWorkingDays.dates.map(date => date.toDateString()));

              setStartDate(date);
              setEndDate(addBusinessDays(date, dateInfo.nonWorkingDays.dates.map(date => date.toDateString()), dateDiff));
            }}
            disabledKeyboardNavigation={true}
            showPopperArrow={false}
            showWeekNumbers
            popperPlacement={'bottom-start'}
            popperModifiers={[
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom-end'],
                  allowedAutoPlacements: ['bottom-end'],
                },
              },
            ]}
          />
        </ReactDatePickerStyle>
      </div>
    </>
  );
};
