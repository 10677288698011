import { Icon } from '@digando/react-component-library';
import React, { FC } from 'react';
import {
  globalSearchEndDateState,
  globalSearchStartDateState,
  useGlobalSearchContext,
} from '../../../../store/global-search.context';
import { useProductSearchValues } from '../../../../store/recoil/product-search';
import { useTranslation } from 'next-i18next';
import { useHandleSubmit } from '../use-handle-submit';
import styled from '@emotion/styled';
import styles from './search-button.module.scss';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';

type SearchButtonProps = {
  preselectedTenantKey: string | null;
  ghostButton?: boolean;
}

export const GlobalSearchBarSearchButton: FC<SearchButtonProps> = ({ preselectedTenantKey, ghostButton }) => {
  const { closeSuggestion } = useGlobalSearchContext();
  const { isResultsLoading } = useProductSearchValues();
  const startDate = useRecoilValue(globalSearchStartDateState);
  const endDate = useRecoilValue(globalSearchEndDateState);
  const [t] = useTranslation(['search-component', 'common']);
  const { handleSubmitSearch } = useHandleSubmit();

  return (
    <button className={classNames({
      [styles.searchButton]: true,
      [styles.ghost]: ghostButton,
    })}
    type={'button'}
    onClick={(): void => handleSubmitSearch({
      preselectedTenantKey,
      newDateRange: {
        gte: startDate,
        lte: endDate,
      },
    })}
    onFocus={(): void => {
      closeSuggestion();
    }}
    aria-label={t('search-component:search-products')}
    data-e2e-id={'globalSearchButton'}
    >
      <span className={styles.btnIcon}>
        {isResultsLoading ? (
          <LoadingIcon size={'35px'} icon={'undo'} color={`var(--white)`} />
        ) : (
          <Icon size={'35px'} icon={'search'} color={`var(--white)`} />
        )}
      </span>
      <span className={styles.btnText}>{t('search-component:search-products')}</span>
    </button>
  );
};

const LoadingIcon = styled(Icon)`
  color: var(--white);

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  animation: spinner 1s linear infinite;
`;
